<script>
import axios from 'axios';
// // import Switches from "vue-switches";
// import Swal from 'sweetalert2';
import Swal from 'sweetalert2';
import Loading from 'vue-loading-overlay';

export default {
  props: [ ],
  components: {
    Loading
  },
  mounted () {

  },
  data() {
    return {
      data: {
        orderId: '',
        status: '-',
        ipAddress: '-'
      },
      title: '',
      signal: {
        status: '-',
        receive: '-',
        transmit: '-'
      },
      loadingPon: false,
      loadingGetData:false,
      stateTroubleShoot: {
                oltRx: null,
                onuRx: null,
                signal: null,
                onuIP: null,
                upTime: null,
                status: null,
                result: null,
                checkingPayment: null,
                rating: null,
                roName:null,
            }
    }
  },
  methods: {
    resetModal () {
      this.data = {
        orderId: '',
        status: '-',
        ipAddress: '-'
      }
      this.title = '',
      this.signal = {
        status: '-',
        receive: '-',
        transmit: '-'
      }
      this.loadingPon = false
      
    },  
    show (id) {
      // console.log('idnya', id)
      this.getData(id)
      this.$refs.modal.show();
    },
    onOk() {
      // Logika untuk tombol OK, bisa memproses data atau mengonfirmasi tindakan
      
      this.$bvModal.hide('modal'); // Menutup modal setelah tindakan OK
    },
    onCancel() {
      // Logika untuk tombol Cancel, biasanya untuk membatalkan aksi
     
      this.$bvModal.hide('modal'); // Menutup modal
    },
    async getData (id) {
      
      this.loadingGetData= true
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?productId=' + id,).then(async response => {
        
        this.data.orderId = response.data.orderId;
        this.title = 'Produk ' + response.data.orderId
        // console.log("sini", response.data);
        // await axios.get(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?checkSignal=true&orderId=' + this.data.orderId,).then(response2 => {
        //   console.log("sini", response2.data);
        //   if(response2.data.status){
        //     this.signal.status = response2.data.onu_signal
        //     this.signal.text = response2.data.onu_signal + ', ' + response2.data.onu_signal_1490 + '/' + response2.data.onu_signal_1310
        //   }
        // });
      });
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'pppoe-service/' + this.data.orderId).then(async response => {
        
        this.data.roName = response.data.roName
        this.data.ipAddress = response.data.address
      });

      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'stage-detail-troubleshot?troubleshot=' + this.data.orderId).then(response => {
       
                this.stateTroubleShoot = {
                    oltRx: response.data.oltRx,
                    onuRx: response.data.onuRx,
                    onuSignal: response.data.onuSignal,
                    onuIP: response.data.onuIP,
                    onuUptime: response.data.onuUptime,
                    onuState: response.data.onuState,
                    result: response.data.result,
                    checkingPayment: response.data.checkingPayment,
                    roName:this.data.roName                    
                }

      })
     
     
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'pppoe-service?radcheck=' + this.data.orderId).then(async response => {
       
        if (response.data[0].value=='active') {
          this.data.status = 'Aktif'
        } else {
          this.data.status = 'Isolir'
        }
      });
      this.loadingGetData= false
    },
    async getPon () {
      this.loadingPon = true
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?checkSignal=true&orderId=' + this.data.orderId,).then(response2 => {
        // console.log("sini", response2.data);
        if(response2.data.status){
          if (response2.data.onu_signal=='Very good') {
            this.signal.status = 'Baik'
          } else if (response2.data.onu_signal=='Warning') {
            this.signal.status = 'Kurang Baik'
          } else {
            this.signal.status = 'Buruk'
          }
          this.signal.receive = response2.data.onu_signal_1490
          this.signal.transmit = response2.data.onu_signal_1310
          this.loadingPon =false
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Gagal memuat data!",
            showConfirmButton: false,
            timer: 3000,
          });
          this.loadingPon = false
        }
      }).catch(error=>{
        console.log('error',error)
      });
    },
    async restartDevice () {
      const body = {
        orderId: this.data.orderId
      }
      console.log(body);

      Swal.fire({
        title: "Apakah Ingin Melakukan Restart?",
        text: "Router Pelanggan Akan Terputus Beberapa Saat",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya!"
      }).then(async(result) => {
        if (result.isConfirmed) {
          const swalInstance = Swal.fire({
        title: "Sedang Melakukan Restart, Harap Sabar Kucingnya Belum Lari",
        showConfirmButton: false,
        width: 600,
        padding: "3em",
        color: "#716add",
        background: "#fff",
        backdrop: `
          rgba(0,0,0,0.5)
          url("https://sweetalert2.github.io/images/nyan-cat.gif")
          left top
          no-repeat
        `
      });

      // Misalkan ini adalah parameter yang ingin kamu periksa
     // const parameter = true; // Ganti dengan nilai yang sesuai

      // Mengecek parameter
    

      
      // // eslint-disable-next-line no-unused-vars
      await axios.post(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?restart-device=true', body).then(() => {
        // console.log("sini", response.data);
        swalInstance.then(() => {
          Swal.close();
        });
        Swal.fire(
            'Sukses!',
            'Berhasil Melakukan Restart',
            'success'
        )
      }).catch(error=>{
        Swal.fire(
            'Gagal!',
            error.message,
            'error'
        )
      });
        }
      });

      
    }
  },
  watch: {
      
  }
};
</script>
  
<template>
  
  <b-modal id="modal" ref="modal" size="lg" centered :title="title" @hide="resetModal">
    <Loading :active="loadingGetData"> </Loading>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="mt-0">
                                                    <div class="row">
                                                        <h5 class="font-size-14 mt-2">Rekap Jaringan :</h5>
                                                        <div class="col-md-5">
                                                            <ul class="list-unstyled product-desc-list text-muted">
                                                                <li class="mb-1">
                                                                    <i
                                                                        class="uil uil-exchange text-primary me-1 font-size-16"></i>
                                                                    oltRx
                                                                </li>
                                                                <li class="mb-1"><i
                                                                        class="uil uil-exchange text-primary me-1 font-size-16"></i>
                                                                    onuRx</li>
                                                                <li class="mb-1"><i
                                                                        class="uil uil-clock-three text-primary me-1 font-size-16"></i>
                                                                    onuUptime
                                                                </li>
                                                                <li class="mb-2"><i
                                                                        class="uil uil-wifi-router text-primary me-1 font-size-16"></i>
                                                                    onuIP
                                                                </li>
                                                                <li class="mt-2"><i
                                                                        class="uil-map-pin-alt text-primary me-1 font-size-16"></i>
                                                                    Ro Name</li>
                                                                
                                                                
                                                            </ul>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <b-input class="mb-1" size="sm" :value="stateTroubleShoot.oltRx" 
                                                                disabled></b-input>
                                                            <b-input class="mb-1" size="sm"  :value="stateTroubleShoot.onuRx"
                                                                disabled></b-input>
                                                            <b-input class="mb-1" size="sm" :value="stateTroubleShoot.onuUptime"
                                                                 disabled></b-input>
                                                            <b-input class="mb-2" size="sm" :value="stateTroubleShoot.onuIP"
                                                                disabled></b-input>
                                                                <b-input class="mt-2 font-size-11" size="sm"  :value="stateTroubleShoot.roName"  
                                                                disabled  :title="stateTroubleShoot.roName"></b-input>
                                                               

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="mt-3">
                                                    <h5 class="font-size-14">Status Jaringan :</h5>
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <ul class="list-unstyled product-desc-list text-muted">
                                                                <li><i
                                                                        class="uil uil-exchange text-primary me-1 font-size-16"></i>
                                                                    onuState</li>
                                                                <li><i
                                                                        class="uil uil-signal text-primary me-1 font-size-16"></i>
                                                                    onuSignal</li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <b-input class="mb-1" size="sm" :value="stateTroubleShoot.onuState" 
                                                                 disabled></b-input>
                                                            <b-input class="mb-1" size="sm" :value="stateTroubleShoot.onuSignal" 
                                                                 disabled></b-input>
                                                                 
                                                        </div>
                                                        <div class="col-md-5">
                                                            <h5 class="font-size-14">Tagihan Bulan Ini :</h5>
                                                            <ul class="list-unstyled product-desc-list text-muted pt-1">
                                                                <li class="mb-1"><i
                                                                        class="uil uil-bill text-primary me-1 font-size-16"></i>
                                                                    Status</li>

                                                                   

                                                            </ul>
                                                        </div>
                                                        <div class="col-md-5 mt-4">
                                                          <b-input class="mt-1" size="sm"    :value="stateTroubleShoot.checkingPayment"
                                                                disabled></b-input>
                                                                
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                           
                                        </div>

                                        <template #modal-footer>
                                          <div style="position: relative; width: 100%;" >
                                          <!-- Tombol Restart di pojok kiri bawah -->
                                          <b-button
                                            size="md"
                                            variant="primary"
                                            @click="restartDevice"
                                            style="position: absolute; left: 0; bottom: 0; margin-bottom: 10px"
                                          >
                                            <i class="fas fa-redo"></i>
                                            <span class="pl-2" style="padding-left: 10px;">Restart</span>
                                          </b-button>
                                          
                                          <div class="d-flex justify-content-end">
                                            <!-- Tombol OK dan Cancel tetap dengan tata letak asli -->
                                            <b-button size="md" variant="success" style="margin-right: 10px;" @click="onOk">OK</b-button>
                                            <b-button size="md" variant="danger" @click="onCancel">Cancel</b-button>
                                          </div>
                                        </div>
                                    </template>
                                  
                                    
    
    
    
    
  </b-modal>
</template>
  