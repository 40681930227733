<script>
import axios from 'axios';
// import Switches from "vue-switches";
import Swal from 'sweetalert2';

export default {
  props: ['loadOptionDataParent'],
  components: {

    // Switches,

  },

  data() {
    return {
      //   invoicesDetail: [],
      //   checked: false,
      //   keepChangeButton: false,
      nominalPembayaran: '',
      nominalKembalian: null,
      //   printStrukeButton:false,

      //   dataPembayaranInvoices: {
      //     status: "settlement",
      //     updateNextInv: true,
      //     keepChange: false,
      //     cash: "",
      //     method: "tunai",
      //     change: "",
      //     printStrukeButton:false
      //   }
      //   ,
      activityDataSetPayment: {
        user: "",
        message: "Melakukan Pembayaran",
        refRoute: "",
        activityType: "BillPayment",
        handleBy: "",
      },
      data: {
        amount: 0,
        cashChange: 0,
        cashTotal: '',
        grossAmount: 0,
        method: '',
        orderId: '',
        paidAt: null,
        products: '',
        keepChange: false,
        status: ''
      },
      isPrint: false,
      showModalValue: false,
      buttonUpdate: false,
      fieldNominal: false,
      cekfield: true

    }
  },


  methods: {
    async getProduct(id) {

      // console.log(this.$props.orderIdprops);
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'custumer-generate-pembayaran?orderId=' + id,).then(response => {
        this.data = response.data;
        console.log("sini", response.data);
        let routeRef = '/customer/' + response.data.customeriD
        this.activityDataSetPayment.user = response.data.name
        this.activityDataSetPayment.refRoute = routeRef
        this.activityDataSetPayment.handleBy = this.$store.state.authfack.user.username

        if (this.data.amount == 0) {
          this.buttonUpdate = true
        }
        // console.log(this.invoicesDetail.id);
        // console.log(this.data);
      });


    },

    async paidProduct() {


      let dataInvoice = this.dataPembayaranInvoices
      // var a = this.invoicesDetail.id
      // console.log("var a",a);
      //let this.dataPembayaranInvoices

      // console.log("datainv", dataInvoice);

      // await axios.get(process.env.VUE_APP_URL_FEATHERS +'invoices?_id='+idInvoice).then(response => {
      //   await axios.get(process.env.VUE_APP_URL_FEATHERS +'paid-invoices?'+this.idInvoice).then(response => {


      //   // this.invoicesDetail = response.data;
      //   console.log(response.data);
      //   console.log("owekk");

      // });
      Swal.fire({
        title: "Memproses Pembayaran...",
        text: "Harap tunggu sebentar.",
        showConfirmButton: false,
        allowOutsideClick: false,
        width: 600,
        padding: "3em",
        color: "#716add",
        background: "#fff",
        backdrop: `
          rgba(0,0,0,0.5)
          url("https://sweetalert2.github.io/images/nyan-cat.gif")
          left top
          no-repeat
        `
      });

      try {
        const response = await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'paid-invoices/' + this.invoicesDetail.id, dataInvoice);
        console.log('response', response);

        // Tutup swal loading jika proses selesai
        Swal.close();

        // Tampilkan pesan sukses atau tindakan lainnya
        Swal.fire("Sukses!", "Pembayaran berhasil diproses.", "success");

      } catch (error) {
        console.error('error', error);

        // Tutup swal loading jika terjadi error
        Swal.close();

        // Tampilkan pesan error atau tindakan lainnya
        Swal.fire("Gagal!", "Terjadi kesalahan dalam memproses pembayaran.", "error");
      }




    },

    propsInvoiceId() {
      this.$emit('propsInvoiceId');

      this.showModal();

    },
    async recentActivity() {
      await axios.post(process.env.VUE_APP_URL_FEATHERS + 'recent-activity', this.activityDataSetPayment).then(async responseAct => {
        console.log("berhasil post activity", responseAct);
      }).catch((errorAct) => {
        console.log(errorAct);
      });
    },

    async showModal(id) {
      await this.getProduct(id)

      // console.log('idnya', id)
      this.showModalValue = true
      this.$refs['my-modal'].show()
    },
    hideModal() {
      // console.log(this.$props);
      this.orderIdprops = "kikuk"
      this.$refs['my-modal'].hide()

    },
    async confrimHideModal() {
      // if (this.isPrint) {
      //     this.print()
      // }
      // console.log('datapatch', this.data)
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

      Swal.fire({
        title: 'Apakah anda ingin melakukan pembayaran?',
        text: "Silahkan Pastikan Data yang anda masukan tepat",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Menampilkan SweetAlert untuk memulai pemrosesan
          Swal.fire({
            title: "Memproses Pembayaran...",
            text: "Harap tunggu sebentar.",
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            padding: "3em",
            color: "#716add",
            background: "#fff",
            backdrop: `
                      rgba(0,0,0,0.5)
                      url("https://sweetalert2.github.io/images/nyan-cat.gif")
                      left top
                      no-repeat
                  `
          });

          await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'paid-invoices/' + this.data.id, this.data)
            .then(async response => {
              if (response.data.status) {
                this.$emit('loadOptionDataParent');
                this.recentActivity();
                swalWithBootstrapButtons.fire({
                  title: 'Berhasil Melakukan Pembayaran',
                  text: '',
                  backdrop: `
                      rgba(0,0,0,0.5)
                      url("https://sweetalert2.github.io/images/nyan-cat.gif")
                      top center
                      no-repeat
                  `
              });
                this.$refs['my-modal'].hide();
                if (this.isPrint) {
                  this.print();
                }
              } else {
                swalWithBootstrapButtons.fire(
                  'Gagal!',
                  response.data.message,
                  'error'
                );
              }
            }).catch(error => {
              console.log('error', error);
              swalWithBootstrapButtons.fire(
                'Gagal!',
                'Gagal Melakukan Pembayaran',
                'error'
              );
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            'Dibatalkan',
            'Periksa kembali Pembayaran',
            'error'
          );
        }
       })
       
       
    },
    async print() {
      const loggeduser = localStorage.getItem('user');
      const jsonUser = JSON.parse(loggeduser)
      // console.log(this.data.id)
      // console.log(jsonUser.username)
      const obj = {
        invoiceId: this.data.id,
        userName: jsonUser.username
      }
      await axios.post(process.env.VUE_APP_URL_FEATHERS + 'print-invoice', obj)
        .then(async response => {
          // console.log('alamat',response.data.printAddress)
          await axios.post(response.data.printAddress + '/print', response.data)
            .then(async response => {
              console.log(response)
            }).catch(error => {
              console.log('error', error)
            })
        }).catch(error => {
          console.log('error', error)
        })
    },

    async clearfield() {
      this.data.cashTotal = ''
    }
  },
  watch: {
    'data.cashTotal': {
      immediate: true,
      handler(newValue) {
        if (newValue != '' || newValue != 0) {
          this.data.cashChange = newValue - this.data.grossAmount



          if (this.data.cashChange < 0 || this.data.cashChange < 0 || this.data.cashTotal == 0 || this.data.method == '') {
            this.buttonUpdate = false
            console.log("sini false");

          }
          else if (this.nominalKembalian >= 0) {
            this.buttonUpdate = true
            console.log("sini true");
          }
          // this.dataPembayaranInvoices.cash = inputPembayaran
          // this.dataPembayaranInvoices.change = this.nominalKembalian
        }

      },

    },
    'data.method': {
      immediate: true,
      handler() {

        this.fieldNominal = true
        console.log(this.fieldNominal);
      }
    }
  },
};
</script>

<template>
  <div v-if="showModal">
    <!-- <button id="show-btn" @click="propsInvoiceId()" class="btn btn-primary waves-effect waves-light">Pembayaran </button> -->

    <b-modal ref="my-modal" id="modal-center" size="lg" centered title="Invoice Pembayaran" title-class="font-18"
      hide-footer style="display: block; padding-right: 0px;">
      <p>

        <b-form-group class="mb-3" id="example text" label-cols-sm="3" label-cols-lg="3" label="No invoices :"
          label-for="text">
          <b-form-input for="text" v-model="data.orderId" :disabled=true style="width:50%">:</b-form-input>

        </b-form-group>
      </p>
      <p>
        <b-form-group class="mb-3" id="example text" label-cols-sm="3" label-cols-lg="3" label="Nama Paket :"
          label-for="text">
          <b-form-input for="text" v-model="data.products" :disabled=true style="width:50%">:</b-form-input>

        </b-form-group>
      </p>
      <p class="mb-0">
        <b-form-group class="mb-3" id="example text" label-cols-sm="3" label-cols-lg="3" label="Harga Product :"
          label-for="text">
          <b-form-input for="text" v-model="data.amount" :disabled=true style="width:50%">:</b-form-input>

        </b-form-group>

      </p>
      <p class="mb-0">
        <b-form-group class="mb-3" id="example text" label-cols-sm="3" label-cols-lg="3"
          label="Harga Product + ppn 10% :" label-for="text">
          <b-form-input for="text" v-model="data.grossAmount" :disabled=true style="width:50%">:</b-form-input>

        </b-form-group>

      </p>


      <div>
        <h5 class="font-size-14 mb-3">Payment method :</h5>

        <div class="row">
          <div class="col-lg-3 col-sm-6 ">

            <div data-toggle="collapse">
              <label class="card-radio-label">
                <input type="radio" v-model="data.method" name="pay-method" id="transfer" value="transfer"
                  class="card-radio-input" />

                <span class="card-radio text-center text-truncate">
                  <i class="uil uil-postcard d-block h2 mb-3"></i>
                  Transfer
                </span>
              </label>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div>
              <label class="card-radio-label">
                <input type="radio" v-model="data.method" name="pay-method" id="tunai" value="tunai"
                  class="card-radio-input" checked />

                <span class="card-radio text-center text-truncate">
                  <i class="uil uil-money-bill d-block h2 mb-3"></i>
                  <span>Tunai</span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>




      <p class="mb-0">

        <b-form-group class="mb-3" id="example text" label-cols-sm="3" label-cols-lg="3" label="Nominal Pembayaran :"
          label-for="text">
          <b-form-input type="number" for="text" style="width:50%" v-model="data.cashTotal" @click=clearfield
            :disabled="data.method == ''">
          </b-form-input>


        </b-form-group>


      </p>
      <p class="mb-0">
        <b-form-group class="mb-3" id="example text" label-cols-sm="3" label-cols-lg="3" label="Nominal Kembalian :"
          v-model="nominalKembalian" label-for="text">
          <b-form-input v-if="nominalKembalian < 0" for="text" v-model="data.cashChange" :disabled=true
            style="width:50%">:</b-form-input>
          <b-form-input v-if="nominalKembalian >= 0" for="text" v-model="data.cashChange" :disabled=true
            style="width:50%">:</b-form-input>



        </b-form-group>
      </p>
      <p class="mb-0">
        <b-form-checkbox :value=true :unchecked-value=false v-model="data.keepChange" plain>
          Simpan Kembalian Ke Saldo
        </b-form-checkbox>
      </p>
      <p v-if="data.status == 'pending'" class="mb-0">
        <b-form-checkbox :value=true :unchecked-value=false v-model="isPrint" plain>
          Cetak Struk
        </b-form-checkbox>
      </p>

      <div class>



      </div>


      <b-row align-h="between">

        <b-col cols="12" v-if="data.status == 'pending'" class="text-end">
          <b-button v-if="!buttonUpdate" type="submit" variant="primary" class="w-sm" :disabled=true block>
            Update</b-button>
          <b-button v-else-if="buttonUpdate" type="submit" variant="primary" class="w-sm" block
            @click="confrimHideModal">
            Update</b-button>

        </b-col>
        <b-col cols="12" v-if="data.status == 'settlement'" class="text-end">
          <b-button variant="primary" @click="print">
            Cetak Struk
            <i class="bx bxs-printer ms-2"></i>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>



  </div>
</template>
<style>
body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
</style>